@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --color-primary: #00a1ff;
  --color-bg-light: #fff;
  --color-bg-primary: #ecf6f9;
  --color-bg-secondary: #a0b5bb;
  --color-bg-third: #ecf3f8;
  --color-bg-fourth: #d3d8de;
  --color-bg-accent: #a2e9ff;
  --color-link: #5781ff;
  --color-font-primary: #1d3843;
  --color-font-secondary: #777;
  --color-font-third: #b5cbd2;
  --color-warning: #ff8787;
  --color-borders: #e6e6e6;
  --color-font-primary: #1d3843;
}

body {
  margin: 0;
  font-family: 'Mukta', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1d3843;
  font-size: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.uppercase {
  text-transform: uppercase;
}

.font-small {
  font-size: 0.81em;
}

#silc-btn {
  z-index: 999 !important;
}

.DayPickerInput-Overlay {
  z-index: 300 !important;
}
